import React, { useEffect, useState } from 'react'
import Aos from "aos";
import "aos/dist/aos.css"
import { Summary } from './summary'
import { Patch } from './Patch'
import { Workspace } from './Workspace'
import { Compliance } from './Compliance'
import { Inventory } from './Inventory'
import { FaLongArrowAltRight } from 'react-icons/fa';
import { ContactModal } from '../ContactModal'


const Bigfixs = () => {
  const [showContactModal, setShowContactModal] = useState(false);

  const handleRequestDemo = () => {
    setShowContactModal(true);
  };

  const handleCloseContactModal = () => {
    setShowContactModal(false);
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
    <div>

    <h1 style={{ textAlign: "center" }}>BIGFIX</h1>
     <Summary />
     <div 
      data-aos="zoom-in"
      data-aos-offset="200"
      data-aos-easing="ease-in-sine"
      data-aos-duration="600">
     <h2 style={{ textAlign: "center",margin:"60px 0 40px 0" }}>Feature solution</h2>
     </div>
     <Patch />
     <div style={{ margin:"40px 0 40px 0" }}>
     <Workspace />
     </div>
     <div style={{ margin:"40px 0 40px 0" }}>
     <Compliance />
     </div>
     <div style={{ margin:"40px 0 40px 0" }}>
     <Inventory />
     </div>

     <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <button className="custom-button" onClick={handleRequestDemo}>
          <span>
          Request Details <FaLongArrowAltRight />
          </span>
        </button>
      </div>

      <ContactModal style={{zIndex:"100090"}} showModal={showContactModal} onClose={handleCloseContactModal} />
    </div>
    </>
  )
}

export default Bigfixs
import { useState, useEffect } from "react";

const useCountUpAnimation = (target, duration, restartDelay) => {
  const [count, setCount] = useState(0);
  const [restart, setRestart] = useState(false);

  useEffect(() => {
    let animationFrameId;
    let startTimestamp;

    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = (timestamp - startTimestamp) / duration;

      if (progress < 1) {
        const newValue = Math.floor(progress * target);
        setCount(newValue);
        animationFrameId = requestAnimationFrame(step);
      } else {
        setCount(target);

        // Trigger restart after delay
        setTimeout(() => setRestart((prev) => !prev), restartDelay);
      }
    };

    animationFrameId = requestAnimationFrame(step);

    return () => {
      if (animationFrameId) cancelAnimationFrame(animationFrameId);
    };
  }, [target, duration, restart]); // Restart the effect when `restart` changes

  return count;
};

export default useCountUpAnimation;

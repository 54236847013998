import React, { useState } from 'react'
import { Summary } from './Summary'
import {Products} from './Products'
import { FaLongArrowAltRight } from "react-icons/fa";
import { ContactModal } from '../ContactModal';


export const Tenable = () => {
  const [showContactModal, setShowContactModal] = useState(false);

  const handleRequestDemo = () => {
    setShowContactModal(true);
  };

  const handleCloseContactModal = () => {
    setShowContactModal(false);
  };
   
  return (
    <>

    <div>
    
    <h1 style={{ textAlign: "center" }}>TENABLE</h1>
    <Summary />
    <Products />
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
    <button className="custom-button" onClick={handleRequestDemo}>
          <span>
          Request Details <FaLongArrowAltRight />
          </span>
        </button>
      </div>

      <ContactModal style={{zIndex:"100090"}} showModal={showContactModal} onClose={handleCloseContactModal} />
    </div>
  </>
  )
}